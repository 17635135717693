.assignment-form {

  &__public-job.ant-form-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $c_light_grey;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 10px 20px;

    .ant-form-item-label {
      padding: 0;
      margin-right: 16px;
    }

    .ant-form-item-control {
      margin-left: auto;
      flex-grow: 0;

      &-input {
        min-height: auto;

        .ant-switch-checked {
          background-color: $c_green;
        }
      }
    }
  }
}
