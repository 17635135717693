@import '~@yougig/ui/styles/variables';

.updates {
  position: relative;

  .confirm-popover {
    .ant-popover-arrow,
    .ant-popover-inner {
      margin-top: 5px;
    }
    .ant-popover-arrow {
      left: 12px!important;
    }
    .ant-popover-inner {
      transform: translate(-32px);
    }
  }

  &-popover {
    max-width: 100%;
    width: 100%;
    left: 0!important;

    .ant-popover-inner {
      max-width: 676px;
      border: 1px solid var(--c_light_grey);
      box-shadow: none;
    }

    &.updates-popover.ant-popover.with-arrow > .ant-popover-content > .ant-popover-arrow {
      left: 20%;
      right: auto;
    }
  }

  &__info {
    color: $c_hint;
    font-weight: 600;
    margin-bottom: 16px;
  }
  &__timeline {
    margin-top: 30px;
    position: relative;
    padding-top: 32px;

    &__message {
      display: block;
      font-size: 14px;
      line-height: 19px;
      white-space: pre-wrap;
    }

    &__btn {
      position: absolute;
      top: 0;
      left: 20%;
      margin-left: -12px;
      margin-top: -11px;
      z-index: 1;
      color: var(--c_primary);
      cursor: pointer;
      .ant-btn {
        background-color: var(--c_persian_blue);
        box-shadow: none;
        border: none;
        margin-right: 20px;
        &.ant-btn-sm {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          svg {
            width: 13px;
            height: 13px;
          }
        }
      }
    }

    &__show-more {
      .ant-timeline-item-label {
        padding-top: 20px;
        left: 20%;
        transform: translateX(-50%);
      }
    }
  }
  &-form {
    padding: 8px;
    &--footer {
      text-align: right;
    }
  }

  .ant-timeline {
    &-item {
      font-size: 12px;
      padding-bottom: 24px;

      &:first-child {
        .ant-timeline-item-tail {
          top: -30px;
          height: calc(100% + 35px);
        }
      }

      &-last:first-child > .ant-timeline-item-tail {
        display: block;
        height: 30px;
      }
    }
    &-item-label,
    &-item-content {
      top: 0 !important;

      .ant-typography {
        display: block;
        margin-right: 0 !important;
        & + span {
          margin-top: 5px;
        }
      }
    }

    &.ant-timeline-label .ant-timeline-item-label {
      text-align: right;
      .ant-typography {
        color: $c_hint;
      }
    }

    .ant-timeline-item-tail {
      left: 20%;
      top: 5px;
      height: 100%;
    }

    .ant-timeline-item-head {
      left: 20%;
      width: 9px;
      height: 9px;
      border-width: 1px;
      top: 5px;
    }

    .updates__timeline__show-more {
      .ant-timeline-item-head {
        border-color: rgb(131, 133, 236);
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        border-radius: 0;
        background-color: transparent;
        top: -8px;
        width: 7px;
        height: 7px;
        margin-left: -3px;
      }
    }
  }

  .update-remove-btn.ant-btn-icon-only {
    position: absolute;
    left: -50px;
    width: 23px;
    height: 23px;
    border: 1px solid var(--c_primary) !important;
    color: var(--c_primary);
    padding: 6px !important;
    background-color: #fff;
    border-radius: 50%;
    top: 8px;
    transform: translate(-50%, -50%);
  }

  .ant-timeline.ant-timeline-alternate,
  .ant-timeline.ant-timeline-right,
  .ant-timeline.ant-timeline-label {
    .ant-timeline-item-left .ant-timeline-item-content {
      margin: 0;
      left: calc(20% + 50px);
      width: calc(80% - 50px);
    }
  }

  @media (max-width: 480px) {
    .updates__timeline__btn,
    .ant-timeline .ant-timeline-item-tail,
    .ant-timeline .ant-timeline-item-head {
      left: calc(20% + 20px);
    }

    .update-remove-btn.ant-btn-icon-only {
      left: -30px;
    }

    .ant-timeline.ant-timeline-alternate,
    .ant-timeline.ant-timeline-right,
    .ant-timeline.ant-timeline-label {
      .ant-timeline-item-left .ant-timeline-item-content {
        width: calc(80% - 50px);
      }
    }
  }

  @media (max-width: 374px) {
    .updates__timeline__btn,
    .ant-timeline .ant-timeline-item-tail,
    .ant-timeline .ant-timeline-item-head {
      left: calc(20% + 30px);
    }

    .update-remove-btn.ant-btn-icon-only {
      left: -20px;
    }
  }
}
